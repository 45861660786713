import styled, { css } from 'styled-components'
import {
  BPSizes,
  Colors,
  ColumnsGap,
  Palette,
  Rem,
} from '../../../commons/Theme'

export const StyledStep = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;

  @media screen and (min-width: ${BPSizes.desktop}px) {
    gap: 28px;
  }
`

export const StyledStepContainer = styled.div`
  grid-column: 1 / span 12;

  @media screen and (min-width: ${BPSizes.desktop}px) {
    grid-column: 2 / span 10;
  }

  @media screen and (min-width: ${BPSizes.large}px) {
    grid-column: 2 / span 9;
  }
`

export const StyledStepTitle = styled.div`
  font-size: ${Rem(28)};
  font-weight: 400;
  color: ${Palette.Blue['800']};
  margin-bottom: 32px;
  @media screen and (min-width: ${BPSizes.tablet}px) {
    margin-bottom: 40px;
  }
  @media screen and (min-width: ${BPSizes.desktop}px) {
    font-size: ${Rem(32)};
  }
  @media screen and (min-width: ${BPSizes.large}px) {
    font-size: ${Rem(40)};
  }
`

export const StyledCardLabel = styled.div`
  font-size: ${Rem(18)};
  font-weight: 500;
  color: ${Colors.azure600};
  margin-top: 24px;
  transition: inherit;
  @media screen and (min-width: ${BPSizes.desktop}px) {
    font-size: ${Rem(20)};
  }
`

export const StyledCardIcon = styled.div`
  stroke: ${Colors.azure600};
  transition: inherit;
`

export const StyledCardDescription = styled.div`
  font-size: ${Rem(16)};
  font-weight: 500;
  color: ${Palette.Greyscale[800]};
  transition: inherit;
  margin-top: 8px;
`

export const StyledCard = styled.div<{
  $isActive?: boolean
}>`
  background-color: ${Colors.white};
  border-radius: 8px;
  border: 1px solid;
  border-color: ${({ $isActive }) =>
    $isActive ? Colors.azure600 : Colors.blue200};
  padding: 16px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  width: 100%;
  transition:
    color 0.3s,
    background-color 0.3s,
    stroke 0.3s,
    border-color 0.3s;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height: 1.3;
  @media screen and (min-width: ${BPSizes.desktop}px) {
    line-height: 1.35;
    padding: 48px;
  }
  &:hover {
    cursor: pointer;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${Colors.azure600};
      ${StyledCardLabel}, ${StyledCardDescription} {
        color: ${Colors.white};
      }
      ${StyledCardIcon} {
        stroke: ${Colors.white};
      }
    `};
`

export const StyledCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${ColumnsGap.sm};
  row-gap: 16px;
  @media screen and (min-width: ${BPSizes.tablet}px) {
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (min-width: ${BPSizes.md}px) {
    column-gap: ${ColumnsGap.md};
    row-gap: 24px;
  }
`

export const StyledCardRecommendedChip = styled.div<{
  $isRecommended?: boolean
}>`
  visibility: ${({ $isRecommended }) =>
    $isRecommended ? 'visible' : 'hidden'};

  background-color: ${Colors.teal};
  color: ${Colors.dark};
  font-size: 12px;
  font-weight: 400;

  padding: 4px 8px;
  border-radius: 4px;

  text-align: center;
  margin-top: 8px;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0px;
    border-bottom-left-radius: 24px 24px;
    border-top-right-radius: 2px 2px;
    padding: 16px 16px;
    font-size: 14px;
  }
`
