export const WrenchIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.7625 6.58132L24.6838 11.652L21.4078 10.5613L20.3158 7.29065L25.3812 2.23332C22.3838 0.817321 18.7225 1.05865 15.9892 3.05332C12.6918 5.45865 11.5252 9.42665 12.4665 12.9493L2.81582 22.5853C1.04249 24.356 0.781153 27.2693 2.45982 29.1307C4.25049 31.116 7.31449 31.176 9.18249 29.312L19.0185 19.4907C22.1078 20.3133 25.5372 19.5227 27.9612 17.104C30.8105 14.2573 31.4052 10.02 29.7625 6.58132V6.58132Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6665 25.3333V25.3466"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
