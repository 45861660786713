export const PadlockIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3333 30.6667H6.66667C5.19333 30.6667 4 29.4733 4 28V14.6667C4 13.1933 5.19333 12 6.66667 12H25.3333C26.8067 12 28 13.1933 28 14.6667V28C28 29.4733 26.8067 30.6667 25.3333 30.6667Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33325 12V8.00004C9.33325 4.31871 12.3186 1.33337 15.9999 1.33337C19.6813 1.33337 22.6666 4.31871 22.6666 8.00004V12"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 25.3334C18.2091 25.3334 20 23.5425 20 21.3334C20 19.1242 18.2091 17.3334 16 17.3334C13.7909 17.3334 12 19.1242 12 21.3334C12 23.5425 13.7909 25.3334 16 25.3334Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
