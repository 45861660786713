import { CardType } from '../Configuratore.types'
import {
  StyledCard,
  StyledCardDescription,
  StyledCardIcon,
  StyledCardLabel,
  StyledCardRecommendedChip,
} from './Step.style'

export const StepCard = ({
  label,
  description,
  icon,
  onClick,
  isActive,
  isRecommended,
  siblingRecommended,
}: CardType) => {
  return (
    <StyledCard
      role="button"
      tabIndex={0}
      onKeyDown={(e) => (e.code === 'Enter' && onClick ? onClick() : null)}
      onClick={onClick}
      $isActive={isActive}
    >
      <StyledCardIcon>{icon}</StyledCardIcon>
      {siblingRecommended && (
        <StyledCardRecommendedChip $isRecommended={isRecommended}>
          Scelta consigliata
        </StyledCardRecommendedChip>
      )}
      <StyledCardLabel>{label}</StyledCardLabel>
      {description && (
        <StyledCardDescription>{description}</StyledCardDescription>
      )}
    </StyledCard>
  )
}
