import { ArrowRightIcon } from 'components/Icons/ArrowRightIcon'
import { Icon } from 'components/Icons/Icon'
import { LinkProps } from 'components/Link/Link.types'
import {
  eventDataLayer,
  getGA4PageSezione,
  prepareStringForDataLayer,
} from '../commons/utils'
import {
  StyledAccordionLink,
  StyledAtomButtonInner,
  StyledAtomLink,
  StyledAtomLinkProps,
} from './AtomLink.style'
import { AtomButtonColorType, AtomButtonStyleType } from './AtomButton'
import { RuleSet } from 'styled-components'
import { StyledIcon } from './AtomButton.style'
import classNames from 'classnames'

export interface AtomLinkProps extends Omit<LinkProps, 'type'> {
  label?: string
  styleType?: AtomButtonStyleType
  color?: AtomButtonColorType
  icon?: JSX.Element | null
  iconPosition?: 'left' | 'right'
  noIcon?: boolean
  underlined?: boolean
  fullWidth?: boolean
  overrideStyles?: RuleSet<StyledAtomLinkProps>
  labelForDataLayer?: string
  onDataLayer?: () => void
}

interface AtomAccordionLinkProps extends LinkProps {
  label: string
  noIcon?: boolean
  labelForDataLayer?: string
  onDataLayer?: () => void
}

export const AtomAccordionLink = ({
  label,
  to,
  onClick,
  noIcon,
  labelForDataLayer,
  onDataLayer,
  ...rest
}: AtomAccordionLinkProps) => {
  function handleOnClick() {
    if (onDataLayer) {
      onDataLayer()
      return
    }
    eventDataLayer({
      event: 'custom_click',
      stream: 'web',
      label: prepareStringForDataLayer(labelForDataLayer ?? label),
      category: 'internal_link',
      position: 'generic',
      urlButtonDestination: to ? to.split('?')[0] : null,
      sezione: getGA4PageSezione(),
    })
  }

  return (
    <StyledAccordionLink
      to={to}
      onClick={(e) => {
        handleOnClick()
        return onClick?.(e)
      }}
      {...rest}
    >
      <span>{label}</span>
      {!noIcon && (
        <div className="accordion-link__icon-wrapper">
          <Icon Icon={ArrowRightIcon} width="100%" stroke="currentColor" />
        </div>
      )}
    </StyledAccordionLink>
  )
}

export const AtomLink = ({
  label,
  to,
  className,
  target = '_self',
  'aria-disabled': ariaDisabled,
  onClick,
  styleType = 'ghost',
  color = 'blue',
  icon: IconComponent,
  noIcon,
  iconPosition = 'right',
  underlined,
  fullWidth,
  overrideStyles,
  labelForDataLayer,
  onDataLayer,
  ...otherProps
}: AtomLinkProps) => {
  const Icon =
    IconComponent || (styleType === 'tertiary' ? <ArrowRightIcon /> : undefined)

  function handleOnClick() {
    if (onDataLayer) {
      onDataLayer()
      return
    }
    eventDataLayer({
      event: 'custom_click',
      stream: 'web',
      label: prepareStringForDataLayer(labelForDataLayer ?? label),
      category: styleType !== 'ghost' ? 'CTA' : 'internal_link',
      position: 'generic',
      urlButtonDestination: to ? to.split('?')[0] : null,
      sezione: getGA4PageSezione(),
    })
  }

  return (
    <StyledAtomLink
      // ref={ref}
      className={classNames(
        'a2a-atom-link',
        className,
        `a2a-cta-${styleType}`,
        `a2a-cta-color-${color}`
      )}
      tabIndex={0}
      target={target}
      to={to}
      $color={color}
      $hasIcon={
        Icon && !noIcon
          ? styleType === 'tertiary'
            ? 'left'
            : iconPosition
          : undefined
      }
      $styleType={styleType}
      onClick={(e) => {
        if (ariaDisabled === true || ariaDisabled === 'true') {
          e.preventDefault()
          e.stopPropagation()
          return
        }
        handleOnClick()
        return onClick?.(e)
      }}
      $underlined={underlined}
      $fullWidth={fullWidth}
      $overrideStyle={overrideStyles}
      aria-disabled={ariaDisabled}
      $disabled={ariaDisabled === true || ariaDisabled === 'true'}
      {...otherProps}
    >
      <StyledAtomButtonInner
        $iconPosition={styleType === 'tertiary' ? 'left' : iconPosition}
      >
        {label && <span>{label}</span>}
        {Icon && !noIcon && <StyledIcon>{Icon}</StyledIcon>}
      </StyledAtomButtonInner>
    </StyledAtomLink>
  )
}
