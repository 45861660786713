export const StocksDownIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33337 1.33337L13.3334 13.3334L22.6667 5.33337L30.6667 13.3334"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.00004 30.6667C2.52671 30.6667 1.33337 29.4734 1.33337 28V16C1.33337 14.5267 2.52671 13.3334 4.00004 13.3334C5.47337 13.3334 6.66671 14.5267 6.66671 16V28C6.66671 29.4734 5.47337 30.6667 4.00004 30.6667Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 30.6667C14.5267 30.6667 13.3334 29.4734 13.3334 28.0001V25.3334C13.3334 23.8601 14.5267 22.6667 16 22.6667C17.4734 22.6667 18.6667 23.8601 18.6667 25.3334V28.0001C18.6667 29.4734 17.4734 30.6667 16 30.6667Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 30.6667C26.5267 30.6667 25.3334 29.4734 25.3334 28.0001V21.3334C25.3334 19.8601 26.5267 18.6667 28 18.6667C29.4734 18.6667 30.6667 19.8601 30.6667 21.3334V28.0001C30.6667 29.4734 29.472 30.6667 28 30.6667Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.6667 6.66675V13.3334H24"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
