import styled, { RuleSet, css } from 'styled-components'
import Link from 'components/Link/Link'
import { LinkProps } from 'components/Link/Link.types'
import { BPSizes, Palette, Rem, SiteSpacings } from 'commons/Theme'
import { AtomButtonStyleType } from './AtomButton'
import { StyledIcon } from './AtomButton.style'

export interface StyledAtomLinkProps extends LinkProps {
  $styleType?: AtomButtonStyleType
  $color?: string
  $hasIcon?: 'left' | 'right'
  $disabled?: boolean
  $overrideStyle?: RuleSet<StyledAtomLinkProps>
  $underlined?: boolean
  $fullWidth?: boolean
}

type StyledLinkPropsType = (props: StyledAtomLinkProps) => JSX.Element

interface StyledAtomButtonInnerProps {
  $iconPosition?: 'left' | 'right'
}
export const StyledAtomButtonInner = styled.div<StyledAtomButtonInnerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${SiteSpacings.Spacing03.sm}px;

  ${StyledIcon} {
    order: ${({ $iconPosition }) =>
      $iconPosition === 'left' ? '-1' : 'unset'};
  }
`

const StyleButtonBase = css<StyledAtomLinkProps>`
  font-size: ${Rem(18)};
  font-weight: 500;
  line-height: 1.2;
  display: ${({ $fullWidth, $styleType }) =>
    $fullWidth && $styleType !== 'tertiary' ? 'flex' : 'inline-flex'};
  justify-content: center;
  align-items: center;
  width: fit-content;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  ${({ $fullWidth, $styleType }) =>
    $fullWidth &&
    $styleType !== 'tertiary' &&
    css`
      width: 100%;
    `};

  &.full-width:not(.a2a-cta-tertiary) {
    display: flex;
    width: 100%;
  }

  @media screen and (min-width: ${BPSizes.desktop}px) {
    font-size: ${Rem(20)};
    font-weight: 500;
    line-height: 1.2;
  }
`

const StyledGhostButton = css<StyledAtomLinkProps>`
  ${StyleButtonBase};
  gap: ${SiteSpacings.Spacing03.sm}px;

  ${({ $hasIcon, $underlined }) =>
    !$hasIcon &&
    $underlined !== false &&
    css`
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    `};

  ${({ $color, $disabled }) =>
    ($color === 'blue' || $color === 'orange') &&
    css`
      color: ${$disabled ? Palette.Greyscale[700] : Palette.Azure[600]};
      stroke: ${$disabled ? Palette.Greyscale[700] : Palette.Azure[600]};

      &:hover {
        color: ${$disabled ? Palette.Greyscale[700] : Palette.Azure[600]};
        stroke: ${$disabled ? Palette.Greyscale[700] : Palette.Azure[600]};
      }
    `};

  ${({ $color, $disabled }) =>
    $color === 'white' &&
    css`
      color: ${$disabled ? Palette.Greyscale[700] : '#ffffff'};
      stroke: ${$disabled ? Palette.Greyscale[700] : '#ffffff'};

      &:hover {
        color: ${$disabled ? Palette.Greyscale[700] : '#ffffff'};
        stroke: ${$disabled ? Palette.Greyscale[700] : '#ffffff'};
      }
    `};

  ${({ $overrideStyle }) => $overrideStyle};
`

const StyledOutlineButton = css<StyledAtomLinkProps>`
  ${StyleButtonBase};
  padding: ${SiteSpacings.Spacing04.sm}px ${SiteSpacings.Spacing05.sm}px;
  border-style: solid;
  border-width: 1.5px;
  border-radius: 999px;
  gap: ${SiteSpacings.Spacing03.sm}px;

  @media screen and (min-width: ${BPSizes.lg}px) {
    padding: ${SiteSpacings.Spacing04.sm}px ${SiteSpacings.Spacing06.sm}px;
  }

  ${({ $color, $disabled }) =>
    $color === 'white' &&
    css`
      border-color: ${$disabled ? Palette.Greyscale[700] : '#ffffff'};
      color: ${$disabled ? Palette.Greyscale[700] : '#ffffff'};
      stroke: ${$disabled ? Palette.Greyscale[700] : '#ffffff'};

      &:hover {
        border-color: ${$disabled
          ? Palette.Greyscale[700]
          : Palette.Greyscale[500]};
        color: ${$disabled ? Palette.Greyscale[700] : Palette.Greyscale[300]};
        stroke: ${$disabled ? Palette.Greyscale[700] : Palette.Greyscale[300]};
      }
    `};

  ${({ $color, $disabled }) =>
    $color === 'blue' &&
    css`
      border-color: ${$disabled ? Palette.Greyscale[700] : Palette.Azure[600]};
      color: ${$disabled ? Palette.Greyscale[700] : Palette.Azure[600]};
      stroke: ${$disabled ? Palette.Greyscale[700] : Palette.Azure[600]};

      &:hover {
        border-color: ${$disabled ? Palette.Greyscale[700] : Palette.Blue[800]};
        color: ${$disabled ? Palette.Greyscale[700] : Palette.Blue[800]};
        stroke: ${$disabled ? Palette.Greyscale[700] : Palette.Blue[800]};
      }
    `};

  ${({ $color, $disabled }) =>
    $color === 'orange' &&
    css`
      border-color: ${$disabled ? Palette.Greyscale[700] : Palette.Orange[500]};
      color: ${$disabled ? Palette.Greyscale[700] : Palette.Orange[500]};
      stroke: ${$disabled ? Palette.Greyscale[700] : Palette.Orange[500]};

      &:hover {
        border-color: ${$disabled
          ? Palette.Greyscale[700]
          : Palette.Orange[500]};
        color: ${$disabled ? Palette.Greyscale[700] : Palette.Orange[500]};
        stroke: ${$disabled ? Palette.Greyscale[700] : Palette.Orange[500]};
      }
    `};

  ${({ $overrideStyle }) => $overrideStyle};
`

const StyledIconButton = css<StyledAtomLinkProps>`
  ${StyleButtonBase};
  padding: 0;
  border: none;
  margin: none;
  gap: ${SiteSpacings.Spacing04.sm}px;

  ${StyledAtomButtonInner} {
    gap: ${SiteSpacings.Spacing04.sm}px;
  }

  ${StyledIcon} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    stroke: inherit;
    padding: 14px;
    border-radius: 50%;

    @media screen and (min-width: ${BPSizes.lg}px) {
      width: 56px;
      height: 56px;
      padding: 16px;
    }
  }

  ${({ $color, $disabled }) =>
    $color === 'white' &&
    css`
      color: ${$disabled ? Palette.Greyscale[700] : '#ffffff'};
      stroke: ${$disabled ? Palette.Greyscale[700] : Palette.Azure[600]};

      ${StyledIcon} {
        background-color: ${$disabled ? 'transparent' : '#ffffff'};
        border: ${$disabled
          ? `1px solid ${Palette.Greyscale[500]}`
          : '1px solid #ffffff'};
      }

      &:hover {
        color: ${$disabled ? Palette.Greyscale[700] : Palette.Greyscale[500]};
        stroke: ${$disabled ? Palette.Greyscale[700] : Palette.Blue[800]};

        ${StyledIcon} {
          background-color: ${$disabled
            ? 'transparent'
            : Palette.Greyscale[500]};
          border: 1px solid ${Palette.Greyscale[500]};
        }
      }
    `};

  ${({ $color, $disabled }) =>
    ($color === 'blue' || $color === 'orange') &&
    css`
      color: ${$disabled ? Palette.Greyscale[700] : Palette.Azure[600]};
      stroke: ${$disabled ? Palette.Greyscale[700] : Palette.Azure[600]};

      ${StyledIcon} {
        background-color: ${$disabled ? 'transparent' : 'transparent'};
        border: ${$disabled
          ? `1px solid ${Palette.Greyscale[700]}`
          : `1px solid ${Palette.Azure[600]}`};
      }

      &:hover {
        color: ${$disabled ? Palette.Greyscale[700] : Palette.Greyscale[500]};
        stroke: ${$disabled ? Palette.Greyscale[700] : Palette.Blue[800]};

        ${StyledIcon} {
          background-color: ${$disabled ? 'transparent' : Palette.Azure[600]};
          border: ${$disabled
            ? `1px solid ${Palette.Greyscale[700]}`
            : `1px solid ${Palette.Azure[600]}`};
        }
      }

      &:hover {
        color: ${Palette.Azure[600]};
        stroke: #ffffff;

        ${StyledIcon} {
          background-color: ${Palette.Azure[600]};
          border: 1px solid ${Palette.Azure[600]};
        }
      }
    `};

  ${({ $overrideStyle }) => $overrideStyle};
`

const StyledPrimaryButton = css<StyledAtomLinkProps>`
  ${StyleButtonBase};
  padding: ${SiteSpacings.Spacing04.sm}px ${SiteSpacings.Spacing05.sm}px;
  border-radius: 999px;
  gap: ${SiteSpacings.Spacing03.sm}px;

  @media screen and (min-width: ${BPSizes.lg}px) {
    padding: ${SiteSpacings.Spacing04.sm}px ${SiteSpacings.Spacing06.sm}px;
  }

  ${({ $color, $disabled }) =>
    $color === 'white' &&
    css`
      background-color: ${$disabled ? Palette.Greyscale[700] : '#ffffff'};
      color: ${$disabled ? '#ffffff' : Palette.Blue[800]};
      stroke: ${$disabled ? '#ffffff' : Palette.Blue[800]};

      &:hover {
        background-color: ${$disabled
          ? Palette.Greyscale[700]
          : Palette.Greyscale[500]};
        color: ${$disabled ? '#ffffff' : Palette.Blue[800]};
        stroke: ${$disabled ? '#ffffff' : Palette.Blue[800]};
      }
    `};

  ${({ $color, $disabled }) =>
    $color === 'blue' &&
    css`
      background-color: ${$disabled
        ? Palette.Greyscale[700]
        : Palette.Azure[600]};
      color: ${$disabled ? '#ffffff' : '#ffffff'};
      stroke: ${$disabled ? '#ffffff' : '#ffffff'};

      &:hover {
        background-color: ${$disabled
          ? Palette.Greyscale[700]
          : Palette.Azure[800]};
        color: ${$disabled ? '#ffffff' : '#ffffff'};
        stroke: ${$disabled ? '#ffffff' : '#ffffff'};
      }
    `};

  ${({ $color, $disabled }) =>
    $color === 'orange' &&
    css`
      background-color: ${$disabled
        ? Palette.Greyscale[700]
        : Palette.Orange[500]};
      color: ${$disabled ? '#ffffff' : '#ffffff'};
      stroke: ${$disabled ? '#ffffff' : '#ffffff'};

      &:hover {
        background-color: ${$disabled
          ? Palette.Greyscale[700]
          : Palette.Orange[500]};
        color: ${$disabled ? '#ffffff' : '#ffffff'};
        stroke: ${$disabled ? '#ffffff' : '#ffffff'};
      }
    `};

  ${({ $overrideStyle }) => $overrideStyle};
`

export const StyledAtomLink = styled<StyledLinkPropsType>(Link)`
  ${({ $styleType }) =>
    $styleType === 'ghost'
      ? css`
          ${StyledGhostButton};
        `
      : $styleType === 'secondary'
        ? css`
            ${StyledOutlineButton};
          `
        : $styleType === 'tertiary'
          ? css`
              ${StyledIconButton};
            `
          : css`
              ${StyledPrimaryButton};
            `};
`

export const StyledAccordionLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${SiteSpacings.Spacing05.md}px 0;
  font-size: ${Rem(18)};
  font-weight: 500;
  line-height: 1.2;
  color: ${Palette.Blue['800']};
  border-bottom: solid 1px ${Palette.Greyscale['500']};
  margin: 0;
  text-decoration: none;
  transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);

  span {
    flex: 1;
  }

  .accordion-link__icon-wrapper {
    height: ${Rem(44)};
    width: ${Rem(44)};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${Rem(85)};
    border: solid 1px ${Palette.Azure['600']};
    color: ${Palette.Azure['600']};
    padding: ${Rem(14)};
    transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);

    @media screen and (min-width: ${BPSizes.desktop}px) {
      height: ${Rem(56)};
      width: ${Rem(56)};
      padding: ${Rem(16)};
    }
  }

  @media screen and (min-width: ${BPSizes.desktop}px) {
    font-size: ${Rem(20)};
    font-weight: 500;
    line-height: 1.2;
  }

  /* &:hover {
    .accordion-link__icon-wrapper {
      color: #ffffff;
      background-color: ${Palette.Azure['600']};
    }
  } */
`
