import {
  eventDataLayer,
  getGA4PageSezione,
  prepareStringForDataLayer,
} from 'commons/utils'
import Modal from 'components/Modale/Modal'
import { useEffect } from 'react'
import { ModalProps } from '../../Modale/SearchModal/SearchModal.types'
import { StyledInfoBlock } from '../Configuratore.style'

export const ModaleInformazioni = ({ showModal, onClose }: ModalProps) => {
  useEffect(() => {
    if (showModal) {
      eventDataLayer({
        event: 'modale',
        azione: 'modale_impression',
        stream: 'web',
        modale_name: prepareStringForDataLayer('Informazioni sulle operazioni'),
        position:
          getGA4PageSezione() === 'www' ? 'generic' : getGA4PageSezione(),
        sezione: getGA4PageSezione(),
      })
    }
  }, [showModal])

  return (
    <Modal
      show={showModal}
      title="Informazioni sulle operazioni"
      icon="default"
      onClose={onClose}
      fullScreen={true}
    >
      <StyledInfoBlock>
        <h4>Cambiare fornitore o passare al mercato libero</h4>
        <div>
          Voglio cambiare fornitore e passare ad A2A Energia, oppure sono ancora
          in tutela ma voglio passare al Mercato Libero con A2A Energia.
        </div>
      </StyledInfoBlock>
      <StyledInfoBlock>
        <h4>Fare una prima attivazione o riattivazione</h4>
        <div>
          Voglio attivare per la prima volta il mio contatore oppure
          riattivarlo.
        </div>
      </StyledInfoBlock>
      <StyledInfoBlock>
        <h4>Fare una voltura</h4>
        <div>
          Voglio cambiare l'intestazione del contratto di energia elettrica o
          gas, senza interruzione di servizio.
        </div>
      </StyledInfoBlock>
      <StyledInfoBlock>
        <h4>Cambiare offerta</h4>
        <div>
          Sono già cliente a Mercato Libero con A2A Energia ma vorrei cambiare
          offerta.
        </div>
      </StyledInfoBlock>
    </Modal>
  )
}
