import { useStaticQuery, graphql } from 'gatsby'

export const useCpaEntityQuery = (): CpaEntityType[] => {
  const { allCpaEntityCpaEntity } = useStaticQuery<CpaEntityCpaEntityQueryType>(
    graphql`
      query CpaEntityData {
        allCpaEntityCpaEntity(filter: { field_chat_visible: { eq: true } }) {
          nodes {
            __typename
            id
            internal {
              type
            }
            drupal_id
            field_chat_whatsapp
            field_chat_visible
            field_live_chat
            field_inviaci_un_messaggio
            field_url_pagina
          }
        }
      }
    `
  )
  return allCpaEntityCpaEntity.nodes
}

type CpaEntityCpaEntityQueryType = {
  allCpaEntityCpaEntity: {
    nodes: CpaEntityType[]
  }
}

export type CpaEntityType = {
  __typename: string
  id: string
  internal: {
    type: string
  }
  drupal_id: string
  field_chat_whatsapp: boolean
  field_chat_visible: boolean
  field_live_chat: boolean
  field_inviaci_un_messaggio: boolean
  field_url_pagina: string
}
