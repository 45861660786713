import React, { createContext, useRef } from 'react'
import { StepNames } from '../components/Configuratore/Configuratore.types'

interface ConfiguratoreContextType {
  data: React.MutableRefObject<DataObject[]>
  addStepSelection: (stepNumber: number, step: StepNames, value: string) => void
  updateContextData: (currentStepNumber: number) => void
  resetData: () => void
  getStepData: (key: string) => string
}

export const ConfiguratoreContext = createContext<ConfiguratoreContextType>(
  {} as ConfiguratoreContextType
)

export const ConfiguratoreContextProvider = ({
  children,
}: ConfiguratoreContextProviderProps) => {
  const data = useRef<DataObject[]>([])

  function addStepSelection(
    stepNumber: number,
    step: StepNames,
    value: string
  ) {
    data.current = [...data.current, { stepNumber, step, value }]
  }

  function updateContextData(currentStepNumber: number) {
    const removedOldData = data.current.filter(
      (selection) => selection.stepNumber < currentStepNumber
    )
    data.current = removedOldData
  }

  function getStepData(key: string) {
    return data.current.find((it) => it.step === key)?.value || ''
  }

  function resetData() {
    data.current = []
  }

  return (
    <ConfiguratoreContext.Provider
      value={{
        data,
        addStepSelection,
        updateContextData,
        resetData,
        getStepData,
      }}
    >
      {children}
    </ConfiguratoreContext.Provider>
  )
}

type ConfiguratoreContextProviderProps = {
  children: React.ReactNode
}

export type DataObject = {
  stepNumber: number
  step: StepNames
  value: string
}
