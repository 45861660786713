export const CasaIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.668 30.6666V22.6666C10.668 19.7213 13.056 17.3333 16.0013 17.3333C18.9466 17.3333 21.3346 19.7213 21.3346 22.6666V30.6666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33398 27.9999V13.3333L16.0007 1.33325L30.6673 13.3333V27.9999C30.6673 29.4733 29.474 30.6666 28.0006 30.6666H4.00065C2.52732 30.6666 1.33398 29.4733 1.33398 27.9999Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
