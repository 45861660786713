import { BusinessIcon } from '../Icons/BusinessIcon'
import { CasaIcon } from '../Icons/CasaIcon'
import { CardType, StepsType } from './Configuratore.types'
import { CrossingArrowsIcon } from './components/icons/CrossingArrowsIcon'
import { ElectricalCordIcon } from './components/icons/ElectricalCordIcon'
import { ElectricityAndGasIcon } from './components/icons/ElectricityAndGasIcon'
import { ElectricityIcon } from './components/icons/ElectricityIcon'
import { GasIcon } from './components/icons/GasIcon'
import { LightningIcon } from './components/icons/LightningIcon'
import { PadlockIcon } from './components/icons/PadlockIcon'
import { PadlockStock } from './components/icons/PadlockStock'
import { PowerIcon } from './components/icons/PowerIcon'
import { SelfClosingArrows } from './components/icons/SelfClosingArrows'
import { StocksDownIcon } from './components/icons/StocksDownIcon'
import { UserHeartIcon } from './components/icons/UserHeartIcon'
import { WrenchIcon } from './components/icons/WrenchIcon'

const luceGasCards: CardType[] = [
  {
    label: 'Cambio fornitore o passaggio al Mercato Libero',
    value: 'cambio_fornitore',
    icon: <CrossingArrowsIcon />,
    next: 'prezzo',
  },
  {
    label: 'Prima attivazione o riattivazione',
    value: 'prima_attivazione',
    icon: <PowerIcon />,
    next: 'prezzo',
  },
  {
    label: 'Voltura',
    value: 'voltura',
    icon: <LightningIcon />,
    next: 'prezzo',
  },
  {
    label: 'Cambio offerta',
    value: 'cambiare_offerta',
    icon: <SelfClosingArrows />,
    next: 'prezzo',
  },
]

export const stepsData: StepsType = {
  contratto: {
    title: 'Che tipo di contratto devi fare?',
    index: 1,
    cards: [
      {
        label: 'Casa',
        value: 'casa',
        icon: <CasaIcon />,
        next: 'servizio',
      },
      {
        label: 'Business',
        value: 'business',
        icon: <BusinessIcon />,
        next: 'servizio',
      },
    ],
  },
  servizio: {
    title: 'Di quale servizio hai bisogno?',
    index: 2,
    cards: [
      {
        label: 'Luce e gas',
        value: 'dual',
        icon: <ElectricityAndGasIcon />,
        isRecommended: true,
        next: 'dual',
      },
      {
        label: 'Luce',
        value: 'luce',
        icon: <ElectricityIcon />,
        next: 'luce',
      },
      {
        label: 'Gas',
        value: 'gas',
        icon: <GasIcon />,
        next: 'gas',
      },
    ],
  },
  dual: {
    title: 'Qual è la tua esigenza per la Luce?',
    index: 2.5,
    backgroundIcon: '/assets/icons/plug-electric-icon.svg',
    showHelp: true,
    cards: [
      {
        label: 'Cambio fornitore o passaggio al Mercato Libero',
        value: 'cambio_fornitore',
        icon: <CrossingArrowsIcon />,
        next: 'dual_gas',
      },
      {
        label: 'Prima attivazione o riattivazione',
        value: 'prima_attivazione',
        icon: <PowerIcon />,
        next: 'dual_gas',
      },
      {
        label: 'Voltura',
        value: 'voltura',
        icon: <LightningIcon />,
        next: 'dual_gas',
      },
      {
        label: 'Cambio offerta',
        value: 'cambiare_offerta',
        icon: <SelfClosingArrows />,
        next: 'dual_gas',
      },
    ],
  },
  dual_gas: {
    title: 'Qual è la tua esigenza per il Gas?',
    index: 3,
    backgroundIcon: '/assets/icons/flame-icon.svg',
    showHelp: true,
    cards: luceGasCards,
  },
  luce: {
    title: 'Qual è la tua esigenza per la Luce?',
    index: 3,
    backgroundIcon: '/assets/icons/plug-electric-icon.svg',
    showHelp: true,
    cards: luceGasCards,
  },
  gas: {
    title: 'Qual è la tua esigenza per il Gas?',
    index: 3,
    backgroundIcon: '/assets/icons/flame-icon.svg',
    showHelp: true,
    cards: luceGasCards,
  },
  prezzo: {
    title: "Preferisci un'offerta a prezzo fisso o variabile?",
    index: 4,
    cards: [
      {
        label: 'Prezzo variabile',
        value: 'prezzo_variabile',
        icon: <StocksDownIcon />,
        next: 'altri_servizi',
      },
      {
        label: 'Prezzo fisso per 12 mesi',
        value: 'prezzo_fisso_oneyear',
        icon: <PadlockIcon />,
        next: 'altri_servizi',
      },
      {
        label: 'Prezzo fisso per 24 mesi',
        value: 'prezzo_fisso_twoyear',
        icon: <PadlockIcon />,
        next: 'altri_servizi',
      },
      {
        label:
          'Prezzo fisso per 24 mesi per clienti in Servizio a Tutele Graduali',
        value: 'prezzo_fisso_twoyear_stg',
        icon: <PadlockIcon />,
        next: 'offerte',
      },
      {
        label: 'Prezzo fisso + variabile',
        value: 'prezzo_fisso_variabile',
        icon: <PadlockStock />,
        next: 'offerte',
      },
    ],
  },
  altri_servizi: {
    title: 'Hai bisogno anche di altri servizi?',
    index: 5,
    cards: [
      {
        label: 'Sì, assistenza casa e famiglia',
        value: 'assistenza_casa_famiglia',
        description:
          'Servizi di manutenzione casa h24 e prestazioni sanitarie a tariffe scontate',
        icon: (
          <>
            <WrenchIcon />
            <UserHeartIcon />
          </>
        ),
        isRecommended: true,
        next: 'offerte',
      },
      {
        label: 'Sì, assistenza casa',
        value: 'assistenza_casa',
        description: 'Servizi di manutenzione casa h24',
        icon: <WrenchIcon />,
        next: 'offerte',
      },
      {
        label: 'No, vorrei solo un contratto di fornitura',
        value: 'solo_contratto',
        icon: <ElectricalCordIcon />,
        next: 'offerte',
      },
    ],
  },
  offerte: {
    title: 'Abbiamo scelto per te queste offerte:',
    index: 6,
  },
  totalSteps: 6,
}
