import { AtomButton } from 'atoms/AtomButton'
import { AtomLink } from 'atoms/AtomLink'
import { Colors } from 'commons/Theme'
import { StyledStepTitle } from 'components/Configuratore/components/Step.style'
import {
  StyledConfiguratoreProgressBar,
  StyledConfiguratoreProgressBarWrapper,
  StyledGoBackLinksBackground,
  StyledGoBackLinksWrapper,
  StyledOffertaBody,
  StyledOffertaIcon,
  StyledOffertaImage,
  StyledOffertaSubtitle,
  StyledOffertaTitle,
} from 'components/Configuratore/Configuratore.style'
import { Icon } from 'components/Icons/Icon'
import { ConfiguratoreOffertaType } from 'hooks/use-configuratore-offerte'
import { HTMLAttributes, useContext, useEffect, useState } from 'react'
import {
  consoleLog,
  createCookie,
  eventDataLayer,
  getGA4PageSezione,
  prepareStringForDataLayer,
} from '../../commons/utils'
import {
  ConfiguratoreContext,
  DataObject,
} from '../../contexts/ConfiguratoreContext'
import { Step } from '../Configuratore/components/Step'
import { BackIcon } from './components/icons/BackIcon'
import { ElectricityIcon } from './components/icons/ElectricityIcon'
import { GasIcon } from './components/icons/GasIcon'
import {
  StyledConfiguratoreBackButton,
  StyledConfiguratoreBodyWrapper,
  StyledConfiguratoreContainer,
  StyledConfiguratoreHeaderWrapper,
  StyledConfiguratoreOfferteWrapper,
  StyledDescription,
  StyledGoBackLinks,
  StyledOffertaCard,
  StyledOffertaIconsWrapper,
  StyledOffertaImageWrapper,
  StyledOffertaVantaggio,
  StyledOfferteWrapper,
  StyledSemiboldTitle,
} from './Configuratore.style'
import {
  AltriServiziType,
  CardType,
  ConfiguratoreProps,
  ContrattiType,
  GoBackLinksProps,
  OffertaCardProps,
  PrezzoType,
  ProcessiType,
  ProgressBarProps,
  ServiziType,
  StepNames,
} from './Configuratore.types'
import { stepsData } from './stepsData'
import { SimpleHeader } from 'components/Header/SimpleHeader'
import { ArrowRightIcon } from 'components/Icons/ArrowRightIcon'

const ProgressBar = ({ activeStep }: ProgressBarProps) => {
  return (
    <StyledConfiguratoreProgressBarWrapper>
      <StyledConfiguratoreProgressBar
        $progress={stepsData[activeStep].index / stepsData.totalSteps}
      />
    </StyledConfiguratoreProgressBarWrapper>
  )
}

const BackButton = (props: HTMLAttributes<HTMLButtonElement>) => {
  return (
    <StyledConfiguratoreBackButton {...props} tabIndex={0}>
      <Icon
        width="56px"
        hoverStroke={Colors.white}
        stroke={Colors.azure600}
        Icon={BackIcon}
      />
    </StyledConfiguratoreBackButton>
  )
}

const OffertaCard = ({ data, ctaLabel, servizioChoice }: OffertaCardProps) => {
  const { getStepData } = useContext(ConfiguratoreContext)

  return (
    <div role="button" tabIndex={0}>
      <StyledOffertaCard>
        <StyledOffertaImageWrapper>
          {data[`vantaggio_etichetta_${servizioChoice}`] && (
            <StyledOffertaVantaggio>
              {data[`vantaggio_etichetta_${servizioChoice}`]}
            </StyledOffertaVantaggio>
          )}
          <StyledOffertaImage $src={data.image} />
        </StyledOffertaImageWrapper>
        <StyledOffertaBody>
          <div>
            <StyledOffertaIconsWrapper>
              {(data.commodity.dual || data.commodity.luce) && (
                <StyledOffertaIcon>
                  <Icon
                    Icon={ElectricityIcon}
                    width="24px"
                    stroke={Colors.azure600}
                  />
                </StyledOffertaIcon>
              )}
              {(data.commodity.dual || data.commodity.gas) && (
                <StyledOffertaIcon>
                  <Icon Icon={GasIcon} width="24px" stroke={Colors.azure600} />
                </StyledOffertaIcon>
              )}
            </StyledOffertaIconsWrapper>
            <StyledOffertaTitle>{data.titolo || data.label}</StyledOffertaTitle>
            <hr
              style={{
                border: `1px solid ${Colors.grey800Second}`,
                width: '100%',
                margin: '20px 0',
              }}
            ></hr>
            <StyledOffertaSubtitle>{data.sottotitolo}</StyledOffertaSubtitle>
          </div>
          <AtomLink
            label={ctaLabel}
            to={`${data.link}#${servizioChoice}`}
            fullWidth={true}
            target="_self"
            color="blue"
            styleType="secondary"
            noIcon
            onDataLayer={() => {
              eventDataLayer({
                event: 'custom_click',
                stream: 'web',
                label: 'scopri_di_piu',
                category: 'CTA',
                urlButtonDestination: data.link?.split('?')[0],
                position: 'product_card_configuratore',
                offerta: prepareStringForDataLayer(
                  data.titolo || data.label
                ).replace(/[-_]/g, ''),
                contratto: getStepData('contratto'),
                type: getStepData('servizio'),
                prezzo: getStepData('prezzo'),
                altri_servizi: getStepData('altri_servizi'),
                operazione:
                  getStepData('servizio') === 'dual'
                    ? `${getStepData('dual') && 'luce-' + getStepData('dual')}${
                        getStepData('dual_gas') &&
                        '|gas-' + getStepData('dual_gas')
                      }`
                    : getStepData('luce')
                      ? `${getStepData('servizio')}-${getStepData('luce')}`
                      : getStepData('gas')
                        ? `${getStepData('servizio')}-${getStepData('gas')}`
                        : '',
                sezione: getGA4PageSezione(),
              })
            }}
          />
        </StyledOffertaBody>
      </StyledOffertaCard>
    </div>
  )
}

const GoBackLinks = ({ startOver, contrattoChoice }: GoBackLinksProps) => {
  return (
    <StyledGoBackLinksBackground>
      <StyledGoBackLinks>
        <div>
          <StyledSemiboldTitle>Non ti convince?</StyledSemiboldTitle>
          <StyledDescription>Puoi ripetere la configurazione</StyledDescription>
        </div>
        <StyledGoBackLinksWrapper>
          <AtomButton
            label="Cambia le tue risposte"
            icon={<ArrowRightIcon />}
            onClick={startOver}
            styleType="tertiary"
          />
          <AtomLink
            label="Scopri tutte le offerte Luce e Gas"
            icon={<ArrowRightIcon />}
            to={
              contrattoChoice === 'casa'
                ? `${process.env.A2A_OFFERTE_URL}`
                : `${process.env.A2A_BUSINESS_OFFERTE_URL}`
            }
            styleType="tertiary"
          />
        </StyledGoBackLinksWrapper>
      </StyledGoBackLinks>
    </StyledGoBackLinksBackground>
  )
}

const cardsFilterHandler = {
  contratto: (offerte: ConfiguratoreOffertaType[], cards: CardType[]) => {
    const offerteAllowedValues = offerte.map((off) => off.tipologia_offerta)
    return cards.filter((x) =>
      offerteAllowedValues.includes(x.value || x.label)
    )
  },
  servizio: (offerte: ConfiguratoreOffertaType[], cards: CardType[]) => {
    const offerteAllowedValues = offerte.reduce(
      (acc, off) => [...new Set([...acc, ...Object.values(off.commodity)])],
      [] as Array<string | null>
    )
    return cards.filter((x) =>
      offerteAllowedValues.includes(x.value || x.label)
    )
  },
  dual: (offerte: ConfiguratoreOffertaType[], cards: CardType[]) => {
    const offerteAllowedValues = offerte.reduce(
      (acc, off) => [
        ...new Set([...acc, ...Object.values(off.processi_abilitati)]),
      ],
      [] as Array<string | null>
    )
    return cards.filter(
      (x) => x.value && offerteAllowedValues.includes(x.value)
    )
  },
  dual_gas: (offerte: ConfiguratoreOffertaType[], cards: CardType[]) => {
    const offerteAllowedValues = offerte.reduce(
      (acc, off) => [
        ...new Set([...acc, ...Object.values(off.processi_abilitati)]),
      ],
      [] as Array<string | null>
    )
    return cards.filter(
      (x) => x.value && offerteAllowedValues.includes(x.value)
    )
  },
  luce: (offerte: ConfiguratoreOffertaType[], cards: CardType[]) => {
    const offerteAllowedValues = offerte.reduce(
      (acc, off) => [
        ...new Set([...acc, ...Object.values(off.processi_abilitati)]),
      ],
      [] as Array<string | null>
    )
    return cards.filter(
      (x) => x.value && offerteAllowedValues.includes(x.value)
    )
  },
  gas: (offerte: ConfiguratoreOffertaType[], cards: CardType[]) => {
    const offerteAllowedValues = offerte.reduce(
      (acc, off) => [
        ...new Set([...acc, ...Object.values(off.processi_abilitati)]),
      ],
      [] as Array<string | null>
    )
    return cards.filter(
      (x) => x.value && offerteAllowedValues.includes(x.value)
    )
  },
  prezzo: (offerte: ConfiguratoreOffertaType[], cards: CardType[]) => {
    const offerteAllowedValues = offerte.map((off) => off.prezzo)
    return cards.filter((x) =>
      offerteAllowedValues.includes(x.value || x.label)
    )
  },
  altri_servizi: (offerte: ConfiguratoreOffertaType[], cards: CardType[]) => {
    const offerteAllowedValues = offerte.map((off) => off.servizi_abilitati)
    return cards.filter((x) =>
      offerteAllowedValues.includes(x.value || x.label)
    )
  },
  offerte: () => {
    return []
  },
}

const offerteFilterHandler = {
  contratto: (
    offerte: ConfiguratoreOffertaType[],
    selection: ContrattiType
  ) => {
    return offerte.filter((off) => off.tipologia_offerta === selection)
  },
  servizio: (offerte: ConfiguratoreOffertaType[], selection: ServiziType) => {
    return offerte.filter((off) => off.commodity[selection])
  },
  dual: (offerte: ConfiguratoreOffertaType[], selection: ProcessiType) => {
    return offerte.filter((off) => off.processi_abilitati[selection])
  },
  dual_gas: (offerte: ConfiguratoreOffertaType[], selection: ProcessiType) => {
    return offerte.filter((off) => off.processi_abilitati[selection])
  },
  luce: (offerte: ConfiguratoreOffertaType[], selection: ProcessiType) => {
    return offerte.filter((off) => off.processi_abilitati[selection])
  },
  gas: (offerte: ConfiguratoreOffertaType[], selection: ProcessiType) => {
    return offerte.filter((off) => off.processi_abilitati[selection])
  },
  prezzo: (offerte: ConfiguratoreOffertaType[], selection: PrezzoType) => {
    return offerte.filter((off) => off.prezzo === selection)
  },
  altri_servizi: (
    offerte: ConfiguratoreOffertaType[],
    selection: AltriServiziType
  ) => {
    return offerte.filter((off) => off.servizi_abilitati === selection)
  },
}

const cookieProcessHandler: Record<string, string> = {
  cambio_fornitore: 'SW001',
  prima_attivazione: 'ATT01',
  voltura: 'VT01',
  cambiare_offerta: 'CP001',
}

export const Configuratore = ({
  showMobile = true,
  offerteData,
}: ConfiguratoreProps) => {
  const [activeStep, setActiveStep] = useState<StepNames>('contratto')
  const [history, setHistory] = useState<StepNames[]>(['contratto'])
  const [isGoingBack, setIsGoingBack] = useState<boolean>(false)
  const [activeCards, setActiveCards] = useState(
    cardsFilterHandler['contratto'](
      offerteData,
      stepsData['contratto'].cards || []
    )
  )
  const [offerteDisponibili, setOfferteDisponibili] =
    useState<ConfiguratoreOffertaType[]>(offerteData)
  const [goHomeUrl, setGoHomeUrl] = useState('/')

  const { data, addStepSelection, updateContextData, resetData, getStepData } =
    useContext(ConfiguratoreContext)

  function filterOfferte() {
    return data.current.reduce(
      (acc: ConfiguratoreOffertaType[], selection: DataObject) =>
        //@ts-expect-error It should be improved
        offerteFilterHandler[selection.step](acc, selection.value),
      offerteData
    )
  }

  function goToNextStep(stepName: StepNames) {
    const filteredOfferte = filterOfferte()

    consoleLog(
      '%c Offerte Disponibili',
      'background: dodgerblue; color: white; padding: 6px 8px 4px 2px; border-radius: 999px',
      filteredOfferte
    )

    setOfferteDisponibili(filteredOfferte)
    if (filteredOfferte.length == 1 && stepName !== 'servizio') {
      setHistory((prev) => [...prev, 'offerte'])
    } else {
      const cardsToShow = cardsFilterHandler[stepName](
        filteredOfferte,
        stepsData[stepName].cards || []
      )
      setActiveCards(cardsToShow)
      setHistory((prev) => [...prev, stepName])
    }
  }

  function goToPreviousStep() {
    setIsGoingBack(true)
    setHistory((prev) => {
      if (prev.length === 1) {
        window.location.href = goHomeUrl
      } else return prev.slice(0, prev.length - 1)

      return prev
    })
  }

  function addSelectionToContext(selection: string) {
    addStepSelection(stepsData[activeStep].index, activeStep, selection)
  }

  function getServizioChoice() {
    return data.current.find((x) => x.step === 'servizio')?.value as
      | ServiziType
      | undefined
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const contrattoParam = params.get('contratto')
    const servizioParam = params.get('servizio')

    if (contrattoParam) {
      addStepSelection(1, 'contratto', contrattoParam)
      if (servizioParam && ['dual', 'luce', 'gas'].includes(servizioParam)) {
        addStepSelection(2, 'servizio', servizioParam)
        setHistory((prev) => [...prev, 'servizio'])
        //@ts-expect-error Unreachable code error
        goToNextStep(params.get('servizio'))
      } else {
        goToNextStep('servizio')
      }
    }
    if (params.get('servizio')) {
      if (params.get('contratto') === 'casa') {
        setGoHomeUrl(process.env.A2A_DRUPAL_CASA_URL || '/')
      } else if (params.get('contratto') === 'business') {
        setGoHomeUrl(process.env.A2A_DRUPAL_BUSINESS_URL || '/')
      } else {
        setGoHomeUrl('/')
      }
    }
    //remove urlparams
    window.history.replaceState(null, '', window.location.pathname)
  }, [])

  useEffect(() => {
    if (history.length > 0) {
      const lastInHistory: StepNames =
        history[history.length - 1] || 'contratto'
      setActiveStep(lastInHistory)
      if (isGoingBack) {
        updateContextData(stepsData[lastInHistory].index)
        const filteredOfferte = filterOfferte()
        setOfferteDisponibili(filteredOfferte)
        setActiveCards(
          cardsFilterHandler[lastInHistory](
            filteredOfferte,
            stepsData[lastInHistory].cards || []
          )
        )
      }
    }
    setIsGoingBack(false)
  }, [history, isGoingBack])

  useEffect(() => {
    if (activeStep === 'offerte') {
      const processoScelto =
        getStepData('luce') || getStepData('gas') || getStepData('dual')

      createCookie(
        'a2aselectedprocesscatalogue',
        cookieProcessHandler[processoScelto] || '',
        undefined,
        '/',
        '.a2a.it'
      )

      eventDataLayer({
        event: 'configuratore',
        stream: 'web',
        step: 'abbiamo_scelto_per_te_queste_offerte',
        label: 'typ',
        contratto: getStepData('contratto'),
        operazione:
          getStepData('servizio') === 'dual'
            ? `${getStepData('dual') && 'luce-' + getStepData('dual')}${
                getStepData('dual_gas') && '|gas-' + getStepData('dual_gas')
              }`
            : getStepData('luce')
              ? `${getStepData('servizio')}-${getStepData('luce')}`
              : getStepData('gas')
                ? `${getStepData('servizio')}-${getStepData('gas')}`
                : '',
        type: getStepData('servizio'),
        prezzo: getStepData('prezzo'),
        altri_servizi: getStepData('altri_servizi'),
        sezione: getGA4PageSezione(),
      })
    }
  }, [activeStep])

  return (
    <StyledConfiguratoreContainer $showMobile={showMobile}>
      <SimpleHeader />
      <ProgressBar activeStep={activeStep} />
      {activeStep !== 'offerte' ? (
        <StyledConfiguratoreBodyWrapper>
          <StyledConfiguratoreHeaderWrapper>
            {history.length ? (
              <BackButton
                onKeyDown={(e) =>
                  e.code === 'Enter' && goToPreviousStep
                    ? goToPreviousStep()
                    : null
                }
                onClick={goToPreviousStep}
              />
            ) : (
              <div />
            )}
          </StyledConfiguratoreHeaderWrapper>
          <Step
            step={activeStep}
            title={stepsData[activeStep].title}
            cards={activeCards}
            showHelp={!!stepsData[activeStep].showHelp}
            goToNextStep={goToNextStep}
            addSelection={addSelectionToContext}
          />
        </StyledConfiguratoreBodyWrapper>
      ) : (
        <>
          <StyledConfiguratoreOfferteWrapper>
            <StyledStepTitle>
              Abbiamo scelto per te queste offerte:
            </StyledStepTitle>
            <StyledOfferteWrapper
              cols={1}
              tabletCols={2}
              largeCols={3}
              colsTabletGap="28px"
            >
              {offerteDisponibili.slice(0, 3).map((offerta, i) => (
                <OffertaCard
                  key={i}
                  data={offerta}
                  ctaLabel="Scopri di piú"
                  servizioChoice={getServizioChoice() || 'luce'}
                />
              ))}
            </StyledOfferteWrapper>
          </StyledConfiguratoreOfferteWrapper>
          <GoBackLinks
            contrattoChoice={
              (data.current.find((x) => x.step === 'contratto')?.value as
                | ContrattiType
                | undefined) || 'casa'
            }
            startOver={() => {
              setActiveCards(
                cardsFilterHandler['contratto'](
                  offerteData,
                  stepsData['contratto'].cards || []
                )
              )
              resetData()
              setHistory(['contratto'])
              setActiveStep('contratto')
            }}
          />
        </>
      )}
    </StyledConfiguratoreContainer>
  )
}
