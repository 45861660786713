import { forwardRef } from 'react'
import {
  eventDataLayer,
  getGA4PageSezione,
  prepareStringForDataLayer,
} from '../commons/utils'
import {
  StyledAtomButton,
  StyledAtomButtonInner,
  StyledAtomButtonProps,
  StyledIcon,
} from './AtomButton.style'
import { RuleSet } from 'styled-components'
import { ArrowRightIcon } from 'components/Icons/ArrowRightIcon'
import classNames from 'classnames'

/**
 * CTA Types
 *
 * `primary` = default,
 * `secondary` = outline,
 * `tertiary` = icona,
 * `ghost` = ghost
 */
export type AtomButtonStyleType = 'primary' | 'secondary' | 'tertiary' | 'ghost'

export type AtomButtonColorType = 'orange' | 'blue' | 'white'

export interface AtomButtonProps
  extends Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'color' | 'children'
  > {
  label?: string
  styleType?: AtomButtonStyleType
  color?: AtomButtonColorType
  icon?: JSX.Element
  iconPosition?: 'left' | 'right'
  underlined?: boolean
  fullWidth?: boolean
  overrideStyles?: RuleSet<StyledAtomButtonProps>
  labelForDataLayer?: string
  onDataLayer?: () => void
}

export const AtomButton = forwardRef(
  (
    {
      label,
      className,
      onClick,
      onKeyDown,
      type = 'button',
      styleType = 'primary',
      color = 'blue',
      icon: IconComponent,
      iconPosition = 'right',
      underlined,
      fullWidth,
      overrideStyles,
      labelForDataLayer,
      onDataLayer,
      ...otherProps
    }: AtomButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const Icon =
      IconComponent ||
      (styleType === 'tertiary' ? <ArrowRightIcon /> : undefined)

    function handleOnClick() {
      if (onDataLayer) {
        onDataLayer()
      } else {
        eventDataLayer({
          event: 'custom_click',
          stream: 'web',
          label: prepareStringForDataLayer(labelForDataLayer ?? label),
          category: styleType === 'ghost' ? 'internal_link' : 'CTA',
          position: 'generic',
          sezione: getGA4PageSezione(),
        })
      }
    }

    return (
      <StyledAtomButton
        ref={ref}
        tabIndex={0}
        type={type}
        className={classNames(
          'a2a-atom-button',
          `a2a-cta-${styleType}`,
          className
        )}
        $color={color}
        $hasIcon={
          Icon ? (styleType === 'tertiary' ? 'left' : iconPosition) : undefined
        }
        $styleType={styleType}
        onClick={(e) => {
          handleOnClick()
          return onClick?.(e)
        }}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            handleOnClick()
          }
          return onKeyDown?.(e)
        }}
        $underlined={underlined}
        $fullWidth={fullWidth}
        $overrideStyle={overrideStyles}
        {...otherProps}
      >
        <StyledAtomButtonInner
          $iconPosition={styleType === 'tertiary' ? 'left' : iconPosition}
        >
          {label && <span>{label}</span>}
          {Icon && <StyledIcon>{Icon}</StyledIcon>}
        </StyledAtomButtonInner>
      </StyledAtomButton>
    )
  }
)

AtomButton.displayName = 'AtomButton'
