export const ElectricalCordIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66699 16V24C6.66699 27.6813 9.65233 30.6666 13.3337 30.6666C17.015 30.6666 20.0003 27.6813 20.0003 24V6.66665C20.0003 3.72131 22.3883 1.33331 25.3337 1.33331C28.279 1.33331 30.667 3.72131 30.667 6.66665V30.6666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 1.33331V6.66665"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3335 1.33331V6.66665"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.3335 6.66663H12.0002V10.6666C12.0002 13.612 9.61216 16 6.66683 16C3.7215 16 1.3335 13.612 1.3335 10.6666V6.66663Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
