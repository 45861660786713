export const UserHeartIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.667 14.6667C13.9807 14.6667 16.667 11.9804 16.667 8.66669C16.667 5.35298 13.9807 2.66669 10.667 2.66669C7.35328 2.66669 4.66699 5.35298 4.66699 8.66669C4.66699 11.9804 7.35328 14.6667 10.667 14.6667Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2668 20.1133C11.7455 20.044 11.2135 20 10.6668 20C5.52016 20 1.3335 23.2147 1.3335 27.1667V29.3333"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.3335 18.6667C25.6668 18.6667 24.7548 19.5187 24.0002 20.2667C23.2455 19.5187 22.3335 18.6667 20.6668 18.6667C18.4442 18.6667 17.3335 20.4867 17.3335 22.4C17.3335 25.6227 21.2228 28.2667 24.0002 29.3334C26.7775 28.2667 30.6668 25.6227 30.6668 22.4C30.6668 20.4867 29.5562 18.6667 27.3335 18.6667Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
