import { graphql, useStaticQuery } from 'gatsby'

export const useConfiguratoreOfferte = (): ConfiguratoreOffertaType[] => {
  try {
    const { allConfiguratoreOfferteConfiguratoreOfferte } =
      useStaticQuery<AllConfiguratoreOfferteProps>(graphql`
        query AllConfiguratoreOfferte {
          allConfiguratoreOfferteConfiguratoreOfferte {
            nodes {
              label
              tipologia_offerta
              commodity {
                dual
                gas
                luce
              }
              titolo
              sottotitolo
              vantaggio_etichetta_luce
              vantaggio_etichetta_gas
              vantaggio_etichetta_dual
              link
              status
              processi_abilitati {
                cambiare_offerta
                cambio_fornitore
                passaggio_mercato_libero
                prima_attivazione
                voltura
              }
              servizi_abilitati
              image
              prezzo
            }
          }
        }
      `)
    return allConfiguratoreOfferteConfiguratoreOfferte.nodes.filter(
      (x) => x.status
    )
  } catch (error) {
    console.error(error)
  }

  return []
}

type AllConfiguratoreOfferteProps = {
  allConfiguratoreOfferteConfiguratoreOfferte: AllConfiguratoreOfferteType
}

type AllConfiguratoreOfferteType = {
  nodes: ConfiguratoreOffertaType[]
}

export type ConfiguratoreOffertaType = {
  label: string
  tipologia_offerta: string
  commodity: {
    dual: 'dual' | null
    gas: 'gas' | null
    luce: 'luce' | null
  }
  titolo: string
  sottotitolo: string
  vantaggio_etichetta_luce: string | null
  vantaggio_etichetta_gas: string | null
  vantaggio_etichetta_dual: string | null
  link: string
  status: boolean
  processi_abilitati: {
    cambiare_offerta: string | null
    cambio_fornitore: string | null
    passaggio_mercato_libero: string | null
    prima_attivazione: string | null
    voltura: string | null
    riattivare_contatore: string | null
  }
  servizi_abilitati: string
  image: string
  prezzo: string | null
}
