import { ChatActivate } from 'components/Chat/ChatActivate'
import { ConfiguratoreContextProvider } from 'contexts/ConfiguratoreContext'
import { useConfiguratoreOfferte } from 'hooks/use-configuratore-offerte'
import { useCpaEntityQuery } from 'hooks/use-cpa-entity'
import { Helmet } from 'react-helmet'
import { Configuratore } from '../components/Configuratore/Configuratore'
import { A2ADataContextProvider } from '../contexts/A2ADataContext'
import { LayoutContextProvider } from '../contexts/LayoutContext'

const ConfiguratorePage = () => {
  const offerteData = useConfiguratoreOfferte()
  const chatQuery = useCpaEntityQuery()

  return (
    <A2ADataContextProvider>
      <Helmet>
        <title>Configuratore offerte</title>
      </Helmet>
      <LayoutContextProvider>
        <ConfiguratoreContextProvider>
          <Configuratore offerteData={offerteData} />
          <ChatActivate chatQuery={chatQuery} />
        </ConfiguratoreContextProvider>
      </LayoutContextProvider>
    </A2ADataContextProvider>
  )
}

export default ConfiguratorePage
