export const PadlockStock = () => {
  return (
    <svg
      width="100"
      height="32"
      viewBox="0 0 100 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 4V20"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42 12H58"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50 4V20"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42 12H58"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3333 30.6667H6.66667C5.19333 30.6667 4 29.4733 4 28V14.6667C4 13.1933 5.19333 12 6.66667 12H25.3333C26.8067 12 28 13.1933 28 14.6667V28C28 29.4733 26.8067 30.6667 25.3333 30.6667Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33325 12V8.00004C9.33325 4.31871 12.3186 1.33337 15.9999 1.33337C19.6813 1.33337 22.6666 4.31871 22.6666 8.00004V12"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 25.3334C18.2091 25.3334 20 23.5425 20 21.3334C20 19.1242 18.2091 17.3334 16 17.3334C13.7909 17.3334 12 19.1242 12 21.3334C12 23.5425 13.7909 25.3334 16 25.3334Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.3333 1.33337L81.3333 13.3334L90.6666 5.33337L98.6666 13.3334"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.9999 30.6667C70.5266 30.6667 69.3333 29.4734 69.3333 28V16C69.3333 14.5267 70.5266 13.3334 71.9999 13.3334C73.4733 13.3334 74.6666 14.5267 74.6666 16V28C74.6666 29.4734 73.4733 30.6667 71.9999 30.6667Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.9999 30.6667C82.5266 30.6667 81.3333 29.4734 81.3333 28.0001V25.3334C81.3333 23.8601 82.5266 22.6667 83.9999 22.6667C85.4733 22.6667 86.6666 23.8601 86.6666 25.3334V28.0001C86.6666 29.4734 85.4733 30.6667 83.9999 30.6667Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.9999 30.6667C94.5266 30.6667 93.3333 29.4734 93.3333 28.0001V21.3334C93.3333 19.8601 94.5266 18.6667 95.9999 18.6667C97.4733 18.6667 98.6666 19.8601 98.6666 21.3334V28.0001C98.6666 29.4734 97.4719 30.6667 95.9999 30.6667Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.6667 6.66675V13.3334H92"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
