export const BusinessIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.33203 9.33325V4.00125C9.33203 2.52792 10.5267 1.33325 12 1.33325H19.9974C21.4707 1.33325 22.6654 2.52792 22.6654 4.00125V9.33325"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33203 15.8972L13.764 21.6345C15.1827 22.2892 16.816 22.2892 18.2334 21.6345L30.6654 15.8972"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.9987 30.6666H3.9987C2.52536 30.6666 1.33203 29.4733 1.33203 27.9999V11.9999C1.33203 10.5266 2.52536 9.33325 3.9987 9.33325H27.9987C29.472 9.33325 30.6654 10.5266 30.6654 11.9999V27.9999C30.6654 29.4733 29.472 30.6666 27.9987 30.6666Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
