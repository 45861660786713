import { PageChatData } from 'commons/Common.types'
import { consoleLog } from 'commons/utils'
import { CpaEntityType } from 'hooks/use-cpa-entity'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
// import { Chat } from './Chat'
import { UserLoginContext } from '../../contexts/UserLoginContext'

export const ChatActivate = ({
  chatQuery = [],
}: {
  chatQuery: CpaEntityType[]
}) => {
  const [isChatInPage, setIsChatInPage] = useState<boolean>(false)
  // const [chatData, setChatData] = useState<PageChatData>()
  const { state: user } = useContext(UserLoginContext)
  const a2aChatEl = useRef<HTMLElement | null>(null)

  const handleLoginButtonClick = useCallback(() => {
    consoleLog('handleLoginButtonClick')
    document.dispatchEvent(new CustomEvent('A2AShowLoginModal'))
  }, [])

  useEffect(() => {
    if (a2aChatEl.current) {
      a2aChatEl.current.addEventListener(
        'loginButtonClick',
        handleLoginButtonClick
      )
    }

    return () => {
      if (a2aChatEl.current) {
        a2aChatEl.current.removeEventListener(
          'loginButtonClick',
          handleLoginButtonClick
        )
      }
    }
  }, [])

  useEffect(() => {
    const chatData = chatQuery || []
    const currentPathname =
      window?.location?.pathname.replace(/\/*$/g, '') || ''
    const pageInResponse = chatData.find(
      (x: PageChatData) => x.field_url_pagina === currentPathname
    )
    const isChatVisible = !!(
      pageInResponse && pageInResponse.field_chat_visible
    )
    setIsChatInPage(isChatVisible)
    // setChatData(isChatVisible ? pageInResponse : undefined)
  }, [user])

  return (
    <>
      {/* {showChat && (
        <Chat
          hideChat={() => {
            setShowChat(false)
            setHasBeenClosedByUser(true)
          }}
          liveChatEnabled={chatData?.field_live_chat}
          whatsappEnabled={chatData?.field_chat_whatsapp}
          inviaciMessaggioEnabled={chatData?.field_inviaci_un_messaggio}
          onLiveChatClick={handleOnLiveChat}
        />
      )} */}
      <a2a-chat ref={a2aChatEl} show-icon={isChatInPage} />
    </>
  )
}
