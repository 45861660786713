export const GasIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_904_212)">
        <path
          d="M15.5 3C15.5 3 14.409 3.986 13.06 5.475C11.04 2.9 9 1 9 1"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5 23H9.667C6.919 23 5 21.021 5 18.187C5 15.353 8.974 10.789 9.144 10.595L9.667 10L10.19 10.596C10.311 10.734 12.373 13.107 13.54 15.484C14.29 14.327 15.046 13.427 15.108 13.352L15.5 12.889L15.892 13.352C16.019 13.503 19 17.052 19 19.256C19 21.461 17.561 23 15.5 23V23Z"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 1C9 1 1 8.442 1 13.838C1 19.232 4.29 23 9 23H15.5C19.916 23 23 19.575 23 14.671C23 9.766 15.5 3 15.5 3"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_904_212">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
