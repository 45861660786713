import { useContext, useState } from 'react'
import {
  eventDataLayer,
  getGA4PageSezione,
  prepareStringForDataLayer,
} from '../../../commons/utils'
import { ConfiguratoreContext } from '../../../contexts/ConfiguratoreContext'
import { StyledNeedHelpMessage } from '../Configuratore.style'
import { CardType, StepProps } from '../Configuratore.types'
import { ModaleInformazioni } from './ModaleInformazioni'
import {
  StyledCardsWrapper,
  StyledStep,
  StyledStepContainer,
  StyledStepTitle,
} from './Step.style'
import { StepCard } from './StepCard'
import { InfoIcon } from './icons/InfoIcon'

export const Step = ({
  step,
  title,
  cards,
  showHelp,
  goToNextStep,
  addSelection,
}: StepProps) => {
  const [activeCard, setActiveCard] = useState('')
  const [showModal, setShowModal] = useState(false)

  const { getStepData } = useContext(ConfiguratoreContext)

  function handleOnSelect(card: CardType) {
    setActiveCard(card.label)
    addSelection(card.value || card.label)

    eventDataLayer({
      event: 'configuratore',
      stream: 'web',
      step: prepareStringForDataLayer(title),
      label: prepareStringForDataLayer(card.label),
      contratto: getStepData('contratto'),
      operazione:
        getStepData('servizio') === 'dual'
          ? step === 'dual_gas'
            ? `${getStepData('dual_gas') && 'gas-' + getStepData('dual_gas')}`
            : `${getStepData('dual') && 'luce-' + getStepData('dual')}${
                getStepData('dual_gas') && '| gas-' + getStepData('dual_gas')
              }`
          : getStepData('luce')
            ? `${getStepData('servizio')}-${getStepData('luce')}`
            : getStepData('gas')
              ? `${getStepData('servizio')}-${getStepData('gas')}`
              : '',
      type: getStepData('servizio'),
      prezzo: getStepData('prezzo'),
      altri_servizi: getStepData('altri_servizi'),
      sezione: getGA4PageSezione(),
    })

    setTimeout(() => {
      if (card.next) {
        goToNextStep(card.next)
        setActiveCard('')
      }
    }, 500)
  }

  const oneCardRecommended = cards.some((card) => !!card.isRecommended)

  return (
    <StyledStep>
      <StyledStepContainer>
        <StyledStepTitle>{title}</StyledStepTitle>
        <StyledCardsWrapper>
          {cards?.map((card: CardType, id: number) => (
            <StepCard
              key={id}
              label={card.label}
              description={card.description || ''}
              icon={card.icon}
              onClick={() => handleOnSelect(card)}
              isActive={activeCard === card.label}
              isRecommended={card.isRecommended || false}
              siblingRecommended={oneCardRecommended}
            />
          ))}
        </StyledCardsWrapper>
        {showHelp && (
          <>
            <StyledNeedHelpMessage
              onClick={() => setShowModal(true)}
              label="Non lo sai? Ti aiutiamo noi"
              styleType="ghost"
              icon={<InfoIcon />}
              iconPosition="right"
            />
            <ModaleInformazioni
              showModal={showModal}
              onClose={() => setShowModal(false)}
            />
          </>
        )}
      </StyledStepContainer>
    </StyledStep>
  )
}
