export const PowerIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8036_68011)">
        <path
          d="M16.084 8V14.6667"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.084 30.6666C24.1842 30.6666 30.7506 24.1002 30.7506 16C30.7506 7.8998 24.1842 1.33331 16.084 1.33331C7.98381 1.33331 1.41732 7.8998 1.41732 16C1.41732 24.1002 7.98381 30.6666 16.084 30.6666Z"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.0466 10.6666C23.3133 12.0813 24.084 13.9506 24.084 16C24.084 20.4186 20.5026 24 16.084 24C11.6653 24 8.08398 20.4186 8.08398 16C8.08398 13.9506 8.85465 12.0813 10.1213 10.6666"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
