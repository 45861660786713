export const ElectricityAndGasIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_904_41)">
        <path
          d="M12.2284 6C9.38959 2.87316 7 1 7 1C7 1 7.5 2.5 3.81856 8.09609C2.26872 10.4994 1 13.0841 1 15.1055C1 20.2989 5.37488 23 10 23C12.4692 23 13.4671 22.5965 15 21"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.4902 5V8.73524"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4707 5V8.73524"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 8.97656H22.9607V11.4667C22.9607 14.2171 20.7307 16.4471 17.9803 16.4471C15.2299 16.4471 13 14.2171 13 11.4667V8.97656Z"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9805 16.4531V22.6785"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.50745 14C8.50745 14 13 17.5914 13 19.6843C13 21.7772 11.4329 23 9.5 23C7.56712 23 6 21.7772 6 19.6843C6 18.8697 6.53021 17.8281 7.1779 16.8596C8.71641 14.6045 8.50745 14 8.50745 14Z"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_904_41">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
